<template>
    <div class="w-full">
      <!-- update form -->
      <vs-popup :title="`Modifier ${nom}`" fullscreen :active.sync="popupUpdateOpportunite">
        <div class="relative w-full">
          <div class="w-full mb-20">
            <div class="w-full flex justify-end">
              <span class="text-danger mr-1">* </span> = informations requises
            </div>
            <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur les opportunités</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                  <div class="w-full mt-5">
                  <div v-if="nom_compte && id_compte" class="w-full h-16 rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                      <div class="w-full h-full flex justify-between items-center rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                          <div>
                              <span class="">{{ nom_compte }}</span>
                          </div>
                          <div @click="nom_compte = null;id_compte = null" class="cursor-pointer hover:underline">
                              <span class="text-danger">supprimer</span>
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <label class="w-full" for="nom_compte"><span class="text-danger">*</span>Nom du compte</label>
                      <vs-input class="w-full" @input="searchCompte()" @focus="search_compte_focus = true" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherche de comptes correspondants" name="search_compte" v-model="search_compte" />
                      <vs-input class="w-full hidden" type="text" label="" placeholder="" name="nom_compte" v-validate="'required'" v-model="nom_compte" />
                      <div v-if="search_compte_focus" @click="search_compte_focus = true" @mouseover="search_compte_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;">
                      <ul>
                          <li @click="selectCompte(compte)" class="hover:bg-grey-light hover:text-black cursor-pointer px-2 py-3" style="" :key="index" v-for="(compte, index) in search_compte_result">
                              <span>{{ compte.nom }}</span>
                          </li>
                      </ul>
                      </div>
                      <span class="text-danger text-sm" v-show="errors.has('nom_compte')">{{ errors.first('nom_compte') }}</span>
                  </div>
                  </div>
                  <div class="w-full mt-5">
                  <label class="w-full text-black font-semibold" for="nom_proprietaire">*Propriétaire de l'opportunité</label>
                  <div>
                      <span class="mr-2">{{ activeUserInfos ? activeUserInfos.prenoms : '' }}</span>
                      <span>{{ activeUserInfos ? activeUserInfos.nom : '' }}</span>
                  </div>
                  </div>
                  <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="*Nom de l'opportunité" placeholder="" name="nom" v-validate="'required'" v-model="nom" />
                  <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
                  </div>
                  <div class="w-full mt-5">
                  <vs-input class="w-full" type="number" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                  <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                  </div>
                  <div class="w-full mt-5">
                  <vs-input class="w-full" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                  <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                  </div>
                  <div class="w-full mt-5">
                  <vs-select class="w-full" label="*Étape" autocomplete name="etape" v-validate="'required'" v-model="etape" @change="onEtapeChange()" >
                      <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Qualifiée','Analyse','Proposition','Négociation','Fermée gagnée','Fermée perdue']" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('etape')">{{ errors.first('etape') }}</span>
                  </div>
              </div>
              <div class="w-1/2">
                  <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="Probabilité (%)" placeholder="" name="probabilte" v-validate="'required|numeric'" v-model="probabilte" />
                  <span class="text-danger text-sm" v-show="errors.has('probabilte')">{{ errors.first('probabilte') }}</span>
                  </div>
                  <div class="w-full mt-5">
                  <vs-select class="w-full" label="*Catégorie de prévision" autocomplete name="categorie_prevision" v-validate="'required'" v-model="categorie_prevision" >
                      <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Omis','Pipeline','Meilleur cas','Engagement','Fermé']" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('categorie_prevision')">{{ errors.first('categorie_prevision') }}</span>
                  </div>
                  <div class="w-full flex mt-5">
                  <vs-input class="w-full" type="text" label="Étape suivante" placeholder="" name="etape_suivante" v-model="etape_suivante" />
                  </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations complémentaires</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                  <div class="w-full mt-5">
                  <vs-select class="w-full" label="Type" autocomplete name="type_opportunite" v-model="type_opportunite" >
                      <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Business existants','Nouveau Business']" />
                  </vs-select>
                  </div>
              </div>
              <div class="w-1/2">
                  <div class="w-full mt-5">
                  <vs-select class="w-full" label="Origine de la piste" autocomplete name="origine" v-model="origine" >
                      <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Liste des achats','Salon professionnel','Webinaire','Site web','Autre']" />
                  </vs-select>
                  </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur la description</div>
            </div>
            <div class="w-full px-5">
              <div class="w-full mt-5">
                  <vs-textarea label="Description" v-model="description" />
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Autres informations</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <label class="w-full" for="nom_compte">Contrat</label>
                  <vs-input class="w-full" @focus="search_contrat_focus = true" @blur="search_contrat_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Contrats..." name="search_contrat" v-model="search_contrat" />
                  <div v-if="search_contrat_focus" @click="search_contrat_focus = true" @mouseover="search_contrat_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <label class="w-full" for="nom_compte">Devis synchronisé</label>
                  <vs-input class="w-full" @focus="search_devis_focus = true" @blur="search_devis_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Devis..." name="search_devis" v-model="search_devis" />
                  <div v-if="search_devis_focus" @click="search_devis_focus = true" @mouseover="search_devis_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur le système</div>
            </div>
            <div class="w-full flex px-5 py-3">
              <div class="w-1/2 mr-10">
                <span class="font-semibold mr-5">Créé par:</span>
                <span class="mr-1">
                  {{ proprietaire ? `${proprietaire.nom},` : '' }}
                </span>
                <span>
                  {{ formateDate(createdAt) }}
                </span>
              </div>
              <div class="w-1/2">
                <span class="font-semibold mr-5">Dernière modification par: </span>
                <span class="mr-1">
                  {{ proprietaire ? `${proprietaire.nom},` : '' }}
                </span>
                <span>{{ formateDate(updatedAt) }}</span>
              </div>
            </div>
          </div>
          <div class="fixed w-full left-0 bottom-0 flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
            <vs-button @click="popupUpdateOpportunite = !popupUpdateOpportunite" class="mr-2" color="danger" type="border">Annuler</vs-button>
            <vs-button @click="updateOpportunite()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
            <vs-button @click="updateOpportunite()">Enregistrer</vs-button> 
          </div>
        </div>
      </vs-popup>
      <!-- / update form -->
      <!-- close opportunite -->
      <vs-popup title="Fermer cette opportunité" :active.sync="popupFermerOpportunite">
        <div class="w-full">
          <div class="w-full mt-5">
            <vs-select class="w-full" label="*Étape" autocomplete name="close_opportunite_value" v-validate="'required'" v-model="close_opportunite_value" @change="onEtapeChange()" >
              <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Fermée gagnée','Fermée perdue']" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('close_opportunite_value')">{{ errors.first('close_opportunite_value') }}</span>
          </div>
          <div class="w-full flex justify-end border-t mt-5 py-5 px-3" style="background-color: rgb(236, 236, 236);">
            <vs-button @click="popupFermerOpportunite = !popupFermerOpportunite" class="mr-2" color="danger" type="border">Annuler</vs-button>
            <vs-button @click="CloseEtapeOpportunite()">Enregistrer</vs-button> 
          </div>
        </div>
      </vs-popup>
      <!-- /close opportunite -->
      <div class="w-full flex">
        <div class="w-1/3 mr-5">
          <vx-card class="w-full">
            <div class="w-full flex items-center">
              <div class="h-10 w-10 rounded-lg mr-2" style="background-color: #FF5D2D;">
                <img class="h-10" src="../../../assets/images/opportunity_120.png" alt="" srcset="">
              </div>
              <div class="w-full text-base font-bold">
                <span class="mr-1">{{ nom }}</span>
              </div>
            </div>
            <div class="mt-2">
              <div class="btn-group"> 
                <vs-button @click="showUpdateOpportuniteModal(opportunite)" type="border">Modifier</vs-button>
                <vs-button @click="confirmDeleteOpportunite()" type="border">Supprimer</vs-button> 
              </div>
            </div>
          </vx-card>
          <vx-card class="w-full mt-5">
            <div class="w-full">
              <vs-collapse>
                <div class="w-full flex justify-end">
                  <feather-icon v-if="!activeUpdate" @click="activeUpdate=true" icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
                </div>
                <vs-collapse-item :open="true" class="rounded-lg mt-5" style="background-color: #F3F3F3;">
                  <div class="font-semibold" slot="header">
                    Informations sur l' opportunité
                  </div>
                  <div class="w-full bg-white p-2">
                    <div class="w-full mt-5">
                      <div v-if="nom_compte && id_compte" class="w-full h-16 rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                        <div class="w-full h-full flex justify-between items-center rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                          <div>
                            <span class="">{{ nom_compte }}</span>
                          </div>
                          <div @click="nom_compte = null;id_compte = null" class="cursor-pointer hover:underline">
                            <span class="text-danger">supprimer</span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <label class="w-full" for="nom_compte"><span class="text-danger">*</span>Nom du compte</label>
                        <vs-input class="w-full" :disabled="!activeUpdate" @input="searchCompte()" @focus="search_compte_focus = true" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherche de comptes correspondants" name="search_compte" v-model="search_compte" />
                        <vs-input class="w-full hidden" type="text" label="" placeholder="" name="nom_compte" v-validate="'required'" v-model="nom_compte" />
                        <div v-if="search_compte_focus" @click="search_compte_focus = true" @mouseover="search_compte_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;">
                          <ul>
                            <li @click="selectCompte(compte)" class="hover:bg-grey-light hover:text-black cursor-pointer px-2 py-3" style="" :key="index" v-for="(compte, index) in search_compte_result">
                              <span>{{ compte.nom }}</span>
                            </li>
                          </ul>
                        </div>
                        <span class="text-danger text-sm" v-show="errors.has('nom_compte')">{{ errors.first('nom_compte') }}</span>
                      </div>
                    </div>
                    <div class="w-full mt-5">
                      <label class="w-full text-black font-semibold" for="nom_proprietaire">*Propriétaire de l'opportunité</label>
                      <div>
                        <span class="mr-2">{{ activeUserInfos ? activeUserInfos.prenoms : '' }}</span>
                        <span>{{ activeUserInfos ? activeUserInfos.nom : '' }}</span>
                      </div>
                    </div>
                    <div class="w-full mt-5">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="text" label="*Nom de l'opportunité" placeholder="" name="nom" v-validate="'required'" v-model="nom" />
                      <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
                    </div>
                    <div class="w-full mt-5">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="number" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                      <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                    </div>
                    <div class="w-full mt-5">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                      <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                    </div>
                    <div class="w-full mt-5">
                      <vs-select class="w-full" :disabled="!activeUpdate" label="*Étape" autocomplete name="etape" v-validate="'required'" v-model="etape" @change="onEtapeChange()" >
                        <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Qualifiée','Analyse','Proposition','Négociation','Fermée gagnée','Fermée perdue']" />
                      </vs-select>
                      <span class="text-danger text-sm" v-show="errors.has('etape')">{{ errors.first('etape') }}</span>
                    </div>
                    <div class="w-full mt-5">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="text" label="Probabilité (%)" placeholder="" name="probabilte" v-validate="'required|numeric'" v-model="probabilte" />
                      <span class="text-danger text-sm" v-show="errors.has('probabilte')">{{ errors.first('probabilte') }}</span>
                    </div>
                    <div class="w-full mt-5">
                      <vs-select class="w-full" :disabled="!activeUpdate" label="*Catégorie de prévision" autocomplete name="categorie_prevision" v-validate="'required'" v-model="categorie_prevision" >
                        <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Omis','Pipeline','Meilleur cas','Engagement','Fermé']" />
                      </vs-select>
                      <span class="text-danger text-sm" v-show="errors.has('categorie_prevision')">{{ errors.first('categorie_prevision') }}</span>
                    </div>
                    <div class="w-full flex mt-5">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="text" label="Étape suivante" placeholder="" name="etape_suivante" v-model="etape_suivante" />
                    </div>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </vx-card>
        </div>
        <div class="w-2/3 relative">
          <vx-card class="w-full px-2">
            <ul class="stepper relative w-full flex rounded p-0">
              <li @click="etape='Qualifiée'" :class="[etape_en_cours === 'Qualifiée' ? enCours : '' , etape === 'Qualifiée' ? currentClasse : '', close_qualification ? completeClasse : '' ,errorClass]" class="stepper__item hover:bg-grey rounded-l-lg cursor-pointer">Qualifiée</li>
              <li @click="etape='Analyse'" :class="[etape_en_cours === 'Analyse' ? enCours : '' ,etape === 'Analyse' ? currentClasse : '', close_analyse ? completeClasse : '' ,errorClass]" class="stepper__item hover:bg-grey cursor-pointer">Analyse</li>
              <li @click="etape='Proposition'" :class="[etape_en_cours === 'Proposition' ? enCours : '' , etape === 'Proposition' ? currentClasse : '', close_proposition ? completeClasse : '' ,errorClass]" class="stepper__item hover:bg-grey cursor-pointer">Proposition</li>
              <li @click="etape='Négociation'" :class="[etape_en_cours === 'Négociation' ? enCours : '' , etape === 'Négociation' ? currentClasse : '', close_negociation ? completeClasse : '', errorClass]" class="stepper__item hover:bg-grey cursor-pointer">Négociation</li>
              <li @click="etape='Fermée'" :class="[etape_en_cours === 'Fermée' ? enCours : '' , etape === 'Fermée' ? currentClasse : '', close_won ? completeClasse : '', close_lost ? lostClasse : '', errorClass]" class="stepper__item hover:bg-grey rounded-r-lg cursor-pointer">
                <vx-tooltip color="black" text="Fermée" position="bottom"> Fermée </vx-tooltip>
              </li>
            </ul>
            <div class="w-full flex justify-between items-center mt-5">
              <div class="font-semibold">
                status: {{etape}}
              </div>
              <div>
                <vs-button v-if="(etape === 'Fermée gagnée' && close_won ) || (etape === 'Fermée perdue' && close_lost)|| (etape === 'Fermée' && close_won) || (etape === 'Fermée' && close_lost)" @click="showCloseOpportunite()">Modifier Etape fermé(e)</vs-button>
                <vs-button v-else-if ="etape === etape_en_cours" @click="CloseEtapeOpportunite()">Marquer Etape comme terminé(e)</vs-button>
                <vs-button v-else-if="etape === 'Qualifiée' || etape === 'Analyse' || etape === 'Proposition' || etape === 'Négociation'" @click="MarquerOpportuniteEnCour()">Marquer Etape en cours</vs-button>
                <vs-button v-else @click="showCloseOpportunite()">Sélectionner Etape fermé(e)</vs-button>
              </div>
            </div>
            <!-- Qualifiée -->
            <div v-if="etape === 'Qualifiée'" class="w-full flex mt-5">
              <div class="w-1/2 mr-4">
                <div class="w-full flex justify-between">
                  <span class="font-bold">Champs clés</span>
                  <span @click="activeUpdate = true" class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-input class="w-full" :disabled="!activeUpdate" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                    <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                  </div>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-textarea :disabled="!activeUpdate" label="Description" v-model="description" />
                  </div>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-checkbox :disabled="!activeUpdate" v-model="budget_confirme">Budget confirmé</vs-checkbox>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full">
                  <span class="font-bold">Guide de réussite</span>
                </div>
                <div class="mt-10">
                  <div class="font-bold">
                    Qualifiez votre opportunité.
                  </div>
                  <ul class="list-disc mt-1">
                    <li class="mt-1"> - Identifier le besoin de l'entreprise que vous allez résoudre pour elle.</li>
                    <li class="mt-1"> - Confirmer qu'ils disposent d'un budget et d'une approbation.</li>
                    <li class="mt-1"> - Déterminer le calendrier en fonction de leurs besoins.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- /Qualifiée -->
            <!-- Analyse des besoins -->
            <div v-if="etape === 'Analyse'" class="w-full flex mt-5">
              <div class="w-1/2 mr-4">
                  <div class="w-full flex justify-between">
                      <span class="font-bold">Champs clés</span>
                      <span @click="activeUpdate = true" class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                  </div>
                  <div class="w-full flex mt-2">
                    <div class="w-full flex flex-col">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="text" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                      <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                    </div>
                  </div>
                  <div class="w-full flex mt-2">
                    <div class="w-full flex flex-col">
                      <vs-input class="w-full" :disabled="!activeUpdate" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                      <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                    </div>
                  </div>
                  <div class="w-full flex mt-2">
                      <div class="w-full flex flex-col">
                        <vs-checkbox :disabled="!activeUpdate" v-model="survey">Enquête(Survey)</vs-checkbox>
                      </div>
                  </div>
              </div>
              <div class="w-1/2">
                  <div class="w-full">
                    <span class="font-bold">Guide de réussite</span>
                  </div>
                  <div class="mt-10">
                    <div class="font-bold">
                      Identifier les produits ou services requis pour cette opportunité.
                    </div>
                    <ul class="list-disc mt-1">
                      <li class="mt-1"> - Recueillir des témoignages de clients, des références ou des analyses de la concurrence.</li>
                      <li class="mt-1"> - Confirmez vos principales parties prenantes.</li>
                      <li class="mt-1"> - Déterminer les ressources dont vous disposez pour eux.</li>
                    </ul>
                  </div>
              </div>
            </div>
            <!-- /Analyse des besoins -->
            <!-- Proposition -->
            <div v-if="etape === 'Proposition'" class="w-full flex mt-5">
              <div class="w-1/2 mr-4">
                <div class="w-full flex justify-between">
                  <span class="font-bold">Champs clés</span>
                  <span @click="activeUpdate = true" class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-input class="w-full" :disabled="!activeUpdate" type="text" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                    <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                  </div>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-input class="w-full" :disabled="!activeUpdate" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                    <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                  </div>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-checkbox :disabled="!activeUpdate" v-model="roi">Analyse du retour sur investissement réalisée</vs-checkbox>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full">
                  <span class="font-bold">Guide de réussite</span>
                </div>
                <div class="mt-10">
                  <div class="font-bold">
                    Présenter votre solution aux parties prenantes.
                  </div>
                  <ul class="list-disc mt-1">
                    <li class="mt-1"> - Communiquer la valeur de vos solutions.</li>
                    <li class="mt-1"> - Fixer le calendrier et le budget.</li>
                    <li class="mt-1"> - Établissez un plan avec le client pour savoir quand et comment vous allez conclure l'affaire.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- /Proposition -->
            <!-- Négociation -->
            <div v-if="etape === 'Négociation'" class="w-full flex mt-5">
              <div class="w-1/2 mr-4">
                <div class="w-full flex justify-between">
                  <span class="font-bold">Champs clés</span>
                  <span @click="activeUpdate = true" class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-input class="w-full" :disabled="!activeUpdate" type="text" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                    <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                  </div>
                </div>
                <div class="w-full flex mt-2">
                  <div class="w-full flex flex-col">
                    <vs-input class="w-full" :disabled="!activeUpdate" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                    <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full">
                  <span class="font-bold">Guide de réussite</span>
                </div>
                <div class="mt-10">
                  <div class="font-bold">
                    Déterminer le plan de mise en œuvre du projet du client.
                  </div>
                  <ul class="list-disc mt-1">
                    <li class="mt-1"> - Acquérir les accords nécessaires.</li>
                    <li class="mt-1"> - Respecter vos propres procédures internes de remise.</li>
                    <li class="mt-1"> - Obtenir un contrat signé.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- /Négociation -->
          </vx-card>
          <vx-card class="w-full mt-5">
            <div class="w-full">
              <vs-tabs class="w-full">
                <vs-tab class="w-full" label="Activité">
                  <div class="w-full tab-text">
                    <div class="w-full btn-group mt-2"> 
                      <vs-button type="border">
                        <div class="flex items-center">
                          <feather-icon icon="PhoneCallIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                          <span>Consigner un appel</span>
                        </div>
                      </vs-button>
                      <vs-button type="border">                                            
                        <div class="flex items-center">
                          <feather-icon icon="CheckSquareIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                          <span>Nouvelle tâche</span>
                        </div>
                      </vs-button> 
                      <vs-button type="border">
                        <div class="flex items-center">
                          <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                          <span>Nouvelle événement</span>
                        </div>
                      </vs-button>
                      <vs-button type="border">
                        <div class="flex items-center">
                          <feather-icon icon="MailIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                          <span>E-mail</span>
                        </div>
                      </vs-button>
                    </div>
                    <div class="w-full mt-5">
                      <vs-collapse class="px-0">
                        <vs-collapse-item class="rounded-lg" style="background-color: #F3F3F3;">
                          <div class="font-semibold" slot="header">
                            À venir et passés
                          </div>
                          <div class="w-full bg-white p-2">
                              test
                          </div>
                        </vs-collapse-item>
                        <vs-collapse-item class="rounded-lg mt-5" style="background-color: #F3F3F3;">
                            <div class="font-semibold" slot="header">
                                juin . 2023
                            </div>
                        </vs-collapse-item>
                      </vs-collapse>
                    </div>
                  </div>
                </vs-tab>
                <vs-tab label="Détails">
                  <div class="w-full tab-text">
                    <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                      <div>Informations complémentaires</div>
                    </div>
                    <div class="w-full flex px-5">
                      <div class="w-1/2 flex flex-col mr-10">
                        <div class="w-full mt-5">
                          <vs-select class="w-full" :disabled="!activeUpdate" label="Type" autocomplete name="type_opportunite" v-model="type_opportunite" >
                            <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Business existants','Nouveau Business']" />
                          </vs-select>
                        </div>
                      </div>
                      <div class="w-1/2">
                          <div class="w-full mt-5">
                          <vs-select class="w-full" :disabled="!activeUpdate" label="Origine de la piste" autocomplete name="origine" v-model="origine" >
                              <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Liste des achats','Salon professionnel','Webinaire','Site web','Autre']" />
                          </vs-select>
                          </div>
                      </div>
                    </div>
                    <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                      <div>Informations sur la description</div>
                    </div>
                    <div class="w-full px-5">
                      <div class="w-full mt-5">
                        <vs-textarea :disabled="!activeUpdate" label="Description" v-model="description" />
                      </div>
                    </div>
                    <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                      <div>Autres informations</div>
                    </div>
                    <div class="w-full flex px-5">
                      <div class="w-1/2 flex flex-col mr-10">
                        <div class="w-full mt-5">
                          <label class="w-full" for="nom_compte">Contrat</label>
                          <vs-input class="w-full" :disabled="!activeUpdate" @focus="search_contrat_focus = true" @blur="search_contrat_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Contrats..." name="search_contrat" v-model="search_contrat" />
                          <div v-if="search_contrat_focus" @click="search_contrat_focus = true" @mouseover="search_contrat_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                        </div>
                      </div>
                      <div class="w-1/2">
                        <div class="w-full mt-5">
                          <label class="w-full" for="nom_compte">Devis synchronisé</label>
                          <vs-input class="w-full" :disabled="!activeUpdate" @focus="search_devis_focus = true" @blur="search_devis_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Devis..." name="search_devis" v-model="search_devis" />
                          <div v-if="search_devis_focus" @click="search_devis_focus = true" @mouseover="search_devis_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                        </div>
                      </div>
                    </div>
                    <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                      <div>Informations sur le système</div>
                    </div>
                    <div class="w-full flex px-5 py-3">
                      <div class="w-1/2 mr-10">
                        <span class="font-semibold mr-5">Créé par:</span>
                        <span class="mr-1">
                          {{ proprietaire ? `${proprietaire.nom},` : '' }}
                        </span>
                        <span>
                          {{ formateDate(createdAt) }}
                        </span>
                      </div>
                      <div class="w-1/2">
                        <span class="font-semibold mr-5">Dernière modification par: </span>
                        <span class="mr-1">
                          {{ proprietaire ? `${proprietaire.nom},` : '' }}
                        </span>
                        <span>{{ formateDate(updatedAt) }}</span>
                      </div>
                    </div>
                    <div class="w-full flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
                      <vs-button class="mr-2" color="danger" type="border">Annuler</vs-button>
                      <vs-button @click="updateOpportunite()">Enregistrer</vs-button> 
                    </div>
                  </div>
                </vs-tab>
                <vs-tab label="Cotation">
                  <div class="w-full tab-text"></div>
                </vs-tab>
                <vs-tab label="Historique">
                  <div class="w-full tab-text"></div>
                </vs-tab>
              </vs-tabs>
            </div>
          </vx-card>
          <div v-if="activeUpdate" class="fixed w-full left-0 bottom-0 flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
            <vs-button @click="activeUpdate = false" class="mr-2" color="danger" type="border">Annuler</vs-button>
            <vs-button @click="updateOpportunite()">Enregistrer</vs-button> 
          </div>
        </div>
      </div>
    </div>
</template>
<script>
// For custom error message 
import { Validator } from 'vee-validate' 
const dict = { 
  custom: { 
    nom_compte: { required: 'Remplissez le champ Nom du compte.', alpha: '' },
    nom: { required: 'Remplissez le champ Nom de l\'opportunité.', alpha: '' }, 
    date_cloture: { required: 'Remplissez le champ Date de clôture.', alpha: '' },
    etape: { required: 'Remplissez le champ Étape.', alpha: '' },
    categorie_prevision: { required: 'Remplissez le champ Catégorie de prévision.', alpha: '' }
  } 
} 
// register custom messages 
Validator.localize('fr', dict)
export default {
  name: 'OpportuniteView',
  data () {
    return {
      popupCreateOpportunite: false,
      popupUpdateOpportunite: false,
      popupFermerOpportunite: false,
      search_adresse: null,
      activeUserInfos: null,
      activeUpdate: false,
      opportunite: null,

      id_opportunite: null,
      proprietaire: null,
      id_proprietaire: null,
      nom_proprietaire: null,
      id_suspect: null,
      nom: null,
      montant: 0,
      date_cloture: null,
      etape: 'Qualifiée',
      etape_en_cours: '',
      close_opportunite_value: '',
      close_qualification: false,
      close_analyse: false,
      close_proposition: false,
      close_negociation: false,
      close_won: false,
      close_lost: false,
      budget_confirme: true,
      roi: true,
      survey: true,
      probabilte: 10,
      categorie_prevision: 'Pipeline',
      etape_suivante: null,
      type_opportunite: '',
      origine: null,
      description: null,
      contrats: [],
      devis: [],

      createdAt: null,
      updatedAt: null,
      historique_update: [],

      search_compte: null,
      search_compte_focus: false,
      search_compte_result: [],

      search_contrat: null,
      search_contrat_focus: false,
      search_contrat_result: [],

      search_devis: null,
      search_devis_focus: false,
      search_devis_result: [],

      //style
      currentClasse: 'current',
      completeClasse: 'complete',
      lostClasse: 'lost',
      enCours: 'progress',
      errorClass: 'error',
      //compte
      createCompte: 'créer compte',
      id_compte: null,
      nom_compte: null,
      description_compte: null,
      telephome_compte: null,
      mobile_compte: null,
      site_web_compte: null,
      type_compte: null,
      secteur_compte: null,
      nmbre_employes_compte: null,
      rue_compte: null,
      ville_compte: null,
      region_compte: null,
      pays_compte: null,
      code_postal_compte: null,
      rue_expedition_compte: null,
      ville_expedition_compte: null,
      region_expedition_compte: null,
      pays_expedition_compte: null,
      code_postal_expedition_compte: null
    }
  },
  computed: {
    stepClassObject () {
      return {}
    }
  },
  methods: {
    formateDate (date) {
      const today = new Date(date)
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = `0${  dd}`
      if (mm < 10) mm = `0${  mm}`

      return `${dd  }/${  mm  }/${  yyyy}`
    },
    formatDateForInputDate (date) {
      const d = new Date(date)
      let month = `${  d.getMonth() + 1}`
      let day = `${  d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${  month}`
      if (day.length < 2) day = `0${  day}`

      return [year, month, day].join('-')
    },
    onEtapeChange () {
      if (this.etape === 'Qualifiée') {
        this.probabilte = 10
        this.categorie_prevision = 'Pipeline'
      }
      if (this.etape === 'Analyse') {
        this.probabilte = 35
        this.categorie_prevision = 'Pipeline'
      }
      if (this.etape === 'Proposition') {
        this.probabilte = 75
        this.categorie_prevision = 'Meilleur cas'
      }
      if (this.etape === 'Négociation') {
        this.probabilte = 90
        this.categorie_prevision = 'Engagement'
      }
      if (this.close_opportunite_value === 'Fermée gagnée') {
        this.probabilte = 100
        this.categorie_prevision = 'Fermé'
      }
      if (this.close_opportunite_value === 'Fermée perdue') {
        this.probabilte = 0
        this.categorie_prevision = 'Omis'
      }
    },
    searchUser () {
      if (this.search_proprietaire !== null || this.search_proprietaire !== '' || this.search_proprietaire !== undefined) {
        this.$store.dispatch('userManagement/searchUser', this.search_proprietaire)
          .then((response) => {
            this.search_proprietaire_result = response.data.users
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Nous avons rencontré un problème',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    searchCompte () {
      if (this.search_compte !== null || this.search_compte !== undefined || this.search_compte !== '') {
        this.$store.dispatch('compte/searchCompte', this.search_compte)
          .then((response) => {
            this.search_compte_result = response.data.comptes
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Nous avons rencontré un problème',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    selectCompte (compte) {
      this.id_compte = compte._id
      this.nom_compte = compte.nom
    },
    selectUser (user) {
      this.select_prorietaire = {
        _id: user._id,
        nom: user.nom,
        prenoms: user.prenoms
      }
    },
    showUpdateOpportuniteModal (opportunite) {
      this.id_opportunite = opportunite ? opportunite._id : null
      this.proprietaire = opportunite ? {
        id: opportunite.id_proprietaire,
        nom: opportunite.nom_proprietaire
      } : null
      this.id_compte = opportunite ? opportunite.id_compte : null
      this.nom_compte = opportunite ? opportunite.nom_compte : null
      this.nom = opportunite ? opportunite.nom : null
      this.montant = opportunite ? opportunite.montant : 0
      this.date_cloture = opportunite ? this.formatDateForInputDate(opportunite.date_cloture) : null
      this.etape = opportunite ? opportunite.etape : 'Qualifiée'
      this.budget_confirme = opportunite ? opportunite.budget_confirme : true
      this.roi = opportunite ? opportunite.roi : true
      this.survey = opportunite ? opportunite.survey : true
      this.probabilte = opportunite ? opportunite.probabilte : 10
      this.categorie_prevision = opportunite ? opportunite.categorie_prevision : 'Pipeline'
      this.etape_suivante = opportunite ? opportunite.etape_suivante : null
      this.type_opportunite = opportunite ? opportunite.type_opportunite : null
      this.origine = opportunite ? opportunite.origine : null
      this.description = opportunite ? opportunite.description : null
      this.contrats = opportunite ? opportunite.etape_suivante : []
      this.devis = opportunite ? opportunite.etape_suivante : []
      this.createdAt = opportunite ? opportunite.createdAt : null
      this.updatedAt = opportunite ? opportunite.updatedAt : null

      this.popupUpdateOpportunite = !this.popupUpdateOpportunite
    },
    showCloseOpportunite () {
      this.popupFermerOpportunite = !this.popupFermerOpportunite
    },
    createOpportunite () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            id_compte: this.id_compte,
            nom_compte: this.nom_compte,
            nom: this.nom,
            montant: this.montant ? parseInt(this.montant) : 0,
            date_cloture: this.date_cloture,
            etape: this.etape,
            budget_confirme: this.budget_confirme,
            roi: this.roi,
            survey: this.survey,
            probabilte: this.probabilte,
            categorie_prevision: this.categorie_prevision,
            etape_suivante: this.etape_suivante,
            type_opportunite: this.type_opportunite,
            origine: this.origine,
            description: this.description,
            contrats: this.contrats,
            devis: this.devis
          }
          this.$vs.loading({
            type:'sound'
          })

          this.$store.dispatch('opportunite/createOpportunite', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.popupCreateOpportunite = false
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Nous avons rencontré un problème',
                text: `${err}`,
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }

      })
    },
    createOpportuniteAndNew () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            id_compte: this.id_compte,
            nom_compte: this.nom_compte,
            nom: this.nom,
            montant: this.montant ? parseInt(this.montant) : 0,
            date_cloture: this.date_cloture,
            etape: this.etape,
            budget_confirme: this.budget_confirme,
            roi: this.roi,
            survey: this.survey,
            probabilte: this.probabilte,
            categorie_prevision: this.categorie_prevision,
            etape_suivante: this.etape_suivante,
            type_opportunite: this.type_opportunite,
            origine: this.origine,
            description: this.description,
            contrats: this.contrats,
            devis: this.devis
          }
          this.$vs.loading({
            type:'sound'
          })

          this.$store.dispatch('opportunite/createOpportunite', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.reset_data()
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Nous avons rencontré un problème',
                text: `${err}`,
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        }

      })
    },
    updateOpportunite () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id: this.id_opportunite,
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            id_compte: this.id_compte,
            nom_compte: this.nom_compte,
            nom: this.nom,
            montant: this.montant ? parseInt(this.montant) : 0,
            date_cloture: this.date_cloture,
            etape: this.etape,
            budget_confirme: this.budget_confirme,
            roi: this.roi,
            survey: this.survey,
            probabilte: this.probabilte,
            categorie_prevision: this.categorie_prevision,
            etape_suivante: this.etape_suivante,
            type_opportunite: this.type_opportunite,
            origine: this.origine,
            description: this.description,
            contrats: this.contrats,
            devis: this.devis
          }
          this.$vs.loading({
            type:'sound'
          })

          this.$store.dispatch('opportunite/updateOpportunite', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              if (this.popupUpdateOpportunite) {
                this.popupUpdateOpportunite = !this.popupUpdateOpportunite
              }
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Nous avons rencontré un problème',
                text: `${err}`,
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        }

      })
    },
    confirmDeleteOpportunite (id) {
      this.id_opportunite = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer l\'opportunité',
        text: 'Voulez-vous vraiment supprimer cette opportunité ?',
        acceptText: 'Supprimer',
        accept: this.softdeleteOpportunite
      })
    },
    MarquerOpportuniteEnCour () {
      const payload = {
        id: this.id_opportunite,
        id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
        id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
        nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
        etape: this.etape
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('opportunite/MarquerOpportuniteEnCour', payload)
        .then((response) => {
          this.$vs.notify({
            title: 'success',
            text: 'Vos modifications ont été enregistrées.',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.initOpportunite(response)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    CloseEtapeOpportunite () {
      if ((this.etape === 'Négociation' && !this.close_opportunite_value) || (this.etape === 'Fermée' && !this.close_opportunite_value) || (this.etape === 'Fermée perdue' && !this.close_opportunite_value) || (this.etape === 'Fermée gagnée' && !this.close_opportunite_value)) {
        this.showCloseOpportunite()
      } else {
        const payload = {
          id: this.id_opportunite,
          id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
          id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
          nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
          etape: this.etape === 'Qualifiée' ? 'Analyse' : this.etape === 'Analyse' ? 'Proposition' : this.etape === 'Proposition' ? 'Négociation' : this.close_opportunite_value
        }
        this.$vs.loading({
          type:'sound'
        })
  
        this.$store.dispatch('opportunite/CloseEtapeOpportunite', payload)
          .then((response) => {
            this.$vs.notify({
              title: 'success',
              text: 'Vos modifications ont été enregistrées.',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })
            this.popupFermerOpportunite = false
            this.close_opportunite_value = null
            this.initOpportunite(response)
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
          })
      }
    },
    softdeleteOpportunite () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('opportunite/softdeleteOpportunite', this.id_opportunite)
        .then(() => {
          this.$vs.notify({
            title: 'Suspect',
            text: 'L\'opportunité a été supprimé(e)',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'primary'
          })
          this.$router.push('/commercial/opportunites')
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getOpportuniteBySlug () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('opportunite/getOpportuniteBySlug', this.$route.params.slug)
        .then((response) => {
          this.initOpportunite(response)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    initOpportunite (response) {
      this.opportunite = response.data.opportunite

      this.id_opportunite = response.data.opportunite._id
      this.proprietaire =  {
        id: response.data.opportunite.id_proprietaire,
        nom: response.data.opportunite.nom_proprietaire
      }
      this.id_proprietaire = response.data.opportunite.id_proprietaire
      this.nom_proprietaire = response.data.opportunite.nom_proprietaire
      this.id_compte = response.data.opportunite.id_compte
      this.nom_compte = response.data.opportunite.nom_compte
      this.id_suspect = response.data.opportunite.id_suspect
      this.nom = response.data.opportunite.nom
      this.montant = response.data.opportunite.montant
      this.date_cloture = this.formatDateForInputDate(response.data.opportunite.date_cloture)
      this.etape = response.data.opportunite.etape
      this.etape_en_cours = response.data.opportunite.etape
      this.close_qualification = response.data.opportunite.close_qualification
      this.close_analyse = response.data.opportunite.close_analyse
      this.close_proposition = response.data.opportunite.close_proposition
      this.close_negociation = response.data.opportunite.close_negociation
      this.close_won = response.data.opportunite.close_won
      this.close_lost = response.data.opportunite.close_lost
      this.budget_confirme = response.data.opportunite.budget_confirme
      this.roi =  response.data.opportunite.roi
      this.survey = response.data.opportunite.survey
      this.probabilte = response.data.opportunite.probabilte
      this.categorie_prevision = response.data.opportunite.categorie_prevision
      this.etape_suivante = response.data.opportunite.etape_suivante
      this.type_opportunite = response.data.opportunite.type_opportunite
      this.origine = response.data.opportunite.origine
      this.description = response.data.opportunite.description
      this.contrats = response.data.opportunite.contrats
      this.devis = response.data.opportunite.devis
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.activeUserInfo()
    this.getOpportuniteBySlug()
  }
}
</script>
<style scoped>
.stepper {
  list-style: none;
}
.stepper::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  width: calc(100% - 20px);
  background: #ffffff;
}

.stepper__item {
  flex: 100%;
  padding: 20px 20px 20px 40px;
  background: #F3F3F3;
  margin: 0 0 0 -15px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}

.stepper__item.progress {
  background: white !important;
  border: 2px solid #014486;
  color: #014486 !important;
  font-weight: bold !important;
}
.stepper__item.current {
  background: #014486 !important;
  color: white !important;
  font-weight: bold !important;
}
.stepper__item.complete {
  background: #3BA755;
  color: white;
}
.stepper__item.lost {
  background: #EA001E;
  color: white;
}
 .stepper__item:first-child {
  padding: 20px;
  -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}
.stepper__item:last-child {
  -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

</style>